import axios from 'axios';
import {
  PROJECTS_LOADED,
  PROJECT_FAILED,
  PROJECT_LOADING,
  PARTICIPANTS_LOADED,
} from '../utils/consts';
import { setAlert } from './alert';

// TODO: Set some alerts using the alert action generator
export const getProjects = () => async (dispatch) => {
  try {
    dispatch({
      type: PROJECT_LOADING,
    });
    axios
      .get('/api/project/getAll', {
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        dispatch({
          type: PROJECTS_LOADED,
          payload: res.data,
        });
      });
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const createProject = (projectData, history) => async (dispatch) => {
  if (!projectData && (!projectData.name || !projectData.noParticipants)) {
    setAlert('Please Complete the form', 'danger');
    return;
  }
  try {
    const res = await axios.post(
      '/api/project/create',
      { ...projectData },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    dispatch(getProjects());
    history.push(`/project/edit/${res.data.project._id}`);
  } catch (err) {
    console.log(err);
    dispatch(setAlert(err.response.data.errors.msg, 'danger'));
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const updateProject = (projectData, history) => async (dispatch) => {
  if (!projectData) {
    setAlert('Please Fill at least one!', 'danger');
    return;
  }
  try {
    const formData = new FormData();
    if (projectData.bgImg && projectData.bgImg.name) {
      console.log(projectData.bgImg);
      formData.append('bgImg', projectData.bgImg, projectData.bgImg.name);
      delete projectData.bgImg;
    }
    for (var key in projectData) {
      formData.append(key, projectData[key]);
    }
    const res = await axios.post('/api/project/updateHost', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(getProjects());
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Some issues in update', 'danger'));
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const createLink = (projectData) => async (dispatch) => {
  if (!projectData) {
    setAlert('Please Fill at least one!', 'danger');
    return;
  }
  try {
    await axios.post('/api/project/createLink', projectData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch(getProjects());
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Some issues in update', 'danger'));
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const deleteProject = (id) => async (dispatch) => {
  try {
    await axios
      .delete(`/api/project/${id}`)
      .then((res) => dispatch(getProjects()));
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};

export const getAllPax = (projectId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/recording/getallpax/${projectId}`);
    dispatch({
      type: PARTICIPANTS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Some issues in getting pax', 'danger'));
    dispatch({
      type: PROJECT_FAILED,
    });
  }
};
