import React from "react";

const VerificationRequest = () => (
    <div className="row no-gutters">
        <p className="ver-text">
          Thanks for Signing Up.<br></br>
          Please check your email and verify the same.<br></br>
        </p>
    </div>
);
export default VerificationRequest;
