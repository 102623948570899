import { combineReducers } from "redux";
import alert from "./alert";
import register from "./register";
import project from './project';

export default combineReducers({
  alert,
  register,
  project
});
