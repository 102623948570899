import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/register';
import { useHistory } from 'react-router-dom';

const NavbarElement = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.register.isAuthenticated);
  const history = useHistory();
  return (
    <Navbar bg="dark">
      <Navbar.Brand href="/home" style={{ color: 'white' }}>QuickJam</Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          {isAuthenticated ? <Button onClick={() => dispatch(logout())} variant="danger">
            Logout
          </Button> : <Button onClick={() => history.push('/login')} variant="success">
              Login
          </Button>}
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarElement;