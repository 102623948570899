import {
  PROJECTS_LOADED,
  PROJECT_FAILED,
  PROJECT_LOADING,
  PARTICIPANTS_LOADED
} from "../utils/consts";

const initialState = {
  projects: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_LOADING:
      return {
        ...state,
        loading: true
      };
    case PROJECTS_LOADED:
      return {
        ...state,
        loading: false,
        projects: payload
      }
    case PROJECT_FAILED:
      return {
        ...state,
        ...payload,
        loading: false
      };
    case PARTICIPANTS_LOADED:
      return {
        ...state,
        participants: payload,
      };
    default:
      return state;
  }
}
